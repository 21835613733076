import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import IndexSPA from "../components/IndexSPA"
import window from 'global'

const Index = (props) => {
   return (
      <>
        <Helmet
          title="《魏絲貝斯計畫》 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "望向窗外，我看見了光和天際線，還有那些追求著成就與愛以及全世界最大的巧克力棒點心，而我的心油然隨之翩然起舞＿《胃灼熱》",
            },
            {
              property: `og:title`,
              content: "《魏絲貝斯計畫》 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "望向窗外，我看見了光和天際線，還有那些追求著成就與愛以及全世界最大的巧克力棒點心，而我的心油然隨之翩然起舞＿《胃灼熱》",
            },
            // {
            //   property: `og:url`,
            //   content: 'https://online.home-to-all.com' + window.location.pathname,
            // },
            // {
            //   property: `og:image`,
            //   content: 'https://online.home-to-all.com' + require('../images/blog/post_1_10.jpg')
            // }
          ]}
        >
        </Helmet>
         <IndexSPA />
      </>
   )
 };
 
 
 export default Index;